import React, { HTMLAttributes } from 'react';
import styles from '../../styles/QuoteStep.module.scss';
import styles1 from '../../styles/V2/QuoteStep.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useQuoteRouter } from '../../hooks';
import { isDesignTestEnabled } from 'src/config';

export interface QuoteStepProps extends HTMLAttributes<HTMLDivElement> {
    index: number;
    name: string;
    href: string;
    active: boolean;
    valid: boolean;
}

const QuoteStep: React.FC<QuoteStepProps> = ({
    name,
    index,
    active,
    valid,
    style,
    className,
}) => {
    const { setStep } = useQuoteRouter();

    const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        
        if (name === 'Pay Deposit' || name === 'Reserved') return null;

        setStep(index);
    };

    const stylesToUse = isDesignTestEnabled() ? styles1 : styles;

    return (
        <div onClick={handleClick}>
            <div className={`${stylesToUse['quote-step']} ${className}`} style={style}>
                <div
                    className={`${stylesToUse['quote-step--badge']} ${active ? stylesToUse['active'] : ''
                        } ${valid ? stylesToUse['valid'] : ''}`}
                >
                    <span>
                        {valid && !active ? <FontAwesomeIcon icon={faCheck} /> : index}
                    </span>
                </div>
                <span className={stylesToUse['quote-step--name']}>{name}</span>
            </div>
        </div>
    );
};

export default QuoteStep;
