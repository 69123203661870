import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { parse } from 'date-fns';
import {
  selectQuoteSteps,
  setQuoteDescriptor,
  setQuoteEstMoveIn,
  setQuotePostalCode,
  setQuoteLocation,
} from '../../store/quoteSlice';
import { useAppDispatch, useAppSelector, useQuoteRouter } from '../../hooks';
import Navbar from './Navbar';
import Footer from './Footer';
import Notify from '../toolkit/notify/Notify';
import styles from '../../styles/Layout.module.scss';

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  fill?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  isPaySteps?: boolean;
}

const useQuoteRouteListener = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { activeStep, setStep } = useQuoteRouter();
  const steps = useAppSelector(selectQuoteSteps);

  const {
    location: locationCode,
    descriptor,
    estMoveIn,
    postalCode,
  } = router.query;

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (locationCode) {
      dispatch(setQuoteLocation(locationCode as string));
    }

    if (descriptor) {
      dispatch(setQuoteDescriptor(descriptor as string));
    }

    if (estMoveIn) {
      dispatch(
        setQuoteEstMoveIn(parse(estMoveIn as string, 'yyyy-MM-dd', new Date()))
      );
    }

    if (postalCode) {
      dispatch(setQuotePostalCode(postalCode as string));
    }

    const step = steps.find((step) => step.url === router.route);

    if (step && !activeStep) {
      setStep(step.id);
    }
  }, [router.isReady, locationCode, descriptor, estMoveIn, postalCode, router.route, dispatch, steps, activeStep]);

  useEffect(() => {
    if (router.isReady) {
      // Trying to catch instances where a route loads and the activeStep
      // is set to an older route
      setTimeout(() => {
        const step = steps.find((step) => step.url === router.route);

        if (step && activeStep && step.id !== activeStep.id) {
          setStep(step.id);
        }
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);
};

const Layout: React.FC<LayoutProps> = ({
  children,
  fill = false,
  fullWidth = false,
  className,
  style,
  isPaySteps
}) => {
  useQuoteRouteListener();

  return (
    <div
      className={`${styles['page-container']}`}
      style={fill ? { height: '100vh' } : {}}
    >
      <Navbar isPaySteps={isPaySteps} />
      <Container
        className={`position-relative ${className || ''}${
          fill ? ' overflow-hidden' : ''
        }${fullWidth ? ' mw-100' : ''}`}
        style={style}
      >
        {children}
      </Container>
      <Footer />
      <Notify />
    </div>
  );
};

export default Layout;
