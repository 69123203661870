import React from 'react';
import Image from 'next/image';
import QuoteSteps from './QuoteSteps';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isDesignTestEnabled } from 'src/config';
import styles from '../../styles/Navbar.module.scss';
import styles2 from '../../styles/V2/NavBar.module.scss'

interface NavbarProps {
    isPaySteps?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isPaySteps }: { isPaySteps?: boolean }) => {

    const stylesToUse = isDesignTestEnabled() ? styles2 : styles;

    return (
        <div
            className={`d-flex flex-row justify-content-between align-items-center ${stylesToUse['navbar']}`}
            style={{ boxShadow: '0 0 0.5rem 0.25rem rgba(0, 0, 0, 0.125)' }}
        >
            <a href="https://cinchstorage.co.uk">
                <Image
                    alt="Cinch Self Storage"
                    src="/images/logo.png"
                    width={181}
                    height={55}
                />
            </a>
            <QuoteSteps isPaySteps={isPaySteps} className="d-none d-lg-flex flex-grow-1 mx-4" />
            <a
                href="https://cinchstorage.co.uk"
                className={'text-decoration-none text-end'}
                style={{ cursor: 'pointer', width: 181 }}
            >
                {isDesignTestEnabled() ? (
                    <>
                        <div className={'d-none d-lg-flex align-items-center gap-2 text-muted'}>
                            Cancel Quote
                            <FontAwesomeIcon icon={faXmark} size={'lg'} color={'#064dc1'} />
                        </div>
                        <span className={'d-inline d-lg-none'}>
                            <FontAwesomeIcon icon={faXmark} size={'2x'} color={'#064dc1'} />
                        </span></>
                ) : (
                    <>
                        <div className={'d-none d-lg-inline text-muted'}>Back to site</div>
                        <span className={'d-inline d-lg-none'}>
                            <FontAwesomeIcon icon={faXmark} size={'2x'} color={'#064dc1'} />
                        </span>
                    </>
                )}
            </a>
        </div>
    );
};

export default Navbar;
